<template></template>

<script setup>
import axios from "axios";
import Cookies from "js-cookie";

import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { linelogin } from "@/api/index";

import { Toast } from "vant";

const route = useRoute();
const router = useRouter();

const store = useStore();

const code = route.query.code;

if (!code) {
  router.replace("/login");
} else {
  const client_id = process.env.VUE_APP_LINE_CLINE_ID;

  // 檢查state是否為login
  if(route.query.state != 'login'){
    //將state存入sessionStorage
    sessionStorage.setItem("slashToken", encodeURIComponent(route.query.state));
  }


  //判斷domain是否包含localhost
  let redirect_uri = "";
  if (store.state.domain.includes("localhost")) {
    redirect_uri = "http://" + store.state.domain + ":8080/home/linelogin";
  } else {
    redirect_uri = "https://" + store.state.domain + "/home/linelogin";
  }


  axios
    .post(
      "https://api.line.me/oauth2/v2.1/token",
      new URLSearchParams({
        grant_type: "authorization_code",
        code: code,
        redirect_uri: redirect_uri,
        client_id: client_id,
        client_secret: process.env.VUE_APP_LINE_CLIENT_SECRET,
      }),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      }
    )
    .then(async (response) => {
      let res = await linelogin({ token: response.data.id_token });
      if (res.code == 200) {
        Cookies.set("token", res.data.token, {
          expires: 365,
          domain: store.state.sso_domain,
        });
        Cookies.set("uid", res.data.uid, {
          expires: 365,
          domain: store.state.sso_domain,
        });
        Toast("登入成功");

        router.push("/");
      } else if (res.code == 201) {
        sessionStorage.setItem("reg", JSON.stringify(response.data));
        router.push({name:'Register',query: {type:'line'}});
      }
    })
    .catch((error) => {
      Toast("登入失敗");
      router.push("/login");
    });
  // let res = await lineLogin(code)
}
</script>
